<template>
  <div v-if="isDataLoaded">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit User</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->

          <form class="row" id="editForm">
            <div class="col-12 h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.name"
                label="Name"
                required
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              />
            </div>
            <div class="col-12 h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.email"
                label="Email"
                :error-messages="emailErrors"
                @input="$v.formData.email.$touch()"
                @blur="$v.formData.email.$touch()"
                required
              />
            </div>
            <div class="h-75px" v-if="isSalesRefVisible">
              <v-text-field
                dense
                outlined
                v-model="formData.so_reference_code"
                label="Sales Reference Number"
                required
              />
            </div>
            <div class="col-12 h-75px">
              <v-text-field
                dense
                outlined
                v-model="formData.description"
                label="Description"
              />
            </div>
            <div class="col-12 h-75px">
              <v-file-input
                outlined
                v-model="formData.photo"
                show-size
                counter
                multiple
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon=""
                label="Photo"
                clearable
                append-icon="mdi-camera"
              />
            </div>
            <div class="col-12 col-md-6 h-75px">
              <v-autocomplete
                dense
                outlined
                v-model="formData.user_type_id"
                :items="serverData.user_types"
                item-name="text"
                item-value="index"
                label="User type"
                clearable
                :error-messages="user_type_idError"
                @input="$v.formData.user_type_id.$touch()"
                @blur="$v.formData.user_type_id.$touch()"
              />
            </div>
            <div class="col-12 col-md-6 h-75px">
              <v-autocomplete
                v-model="formData.on_leave"
                :items="booleanYesNoValues"
                item-text="text"
                item-value="index"
                label="On leave"
                dense
                outlined
                clearable
              />
            </div>
            <div class="col-12 h-75px" v-if="organizationId != '7'">
              <v-autocomplete
                v-model="formData.warehouses"
                class="warehouse-combobox"
                :items="serverData.warehouses"
                label="Warehouses"
                multiple
                clearable
                dense
                chips
                outlined
                item-value="index"
                item-text="text"
                required
                :error-messages="warehousesError"
                @input="$v.formData.warehouses.$touch()"
                @blur="$v.formData.warehouses.$touch()"
              />
            </div>
            <v-card elevation="2" class="my-4">
              <v-toolbar-title
                class="primary lighten-3 h3 text-white text-center pa-1"
                >Roles</v-toolbar-title
              >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    v-for="role in serverData.roles"
                    :key="role.value"
                  >
                    <v-switch
                      class="pt-0 mt-0"
                      hide-details
                      v-model="formData.roles"
                      color="primary"
                      :label="role.text"
                      :value="role.index"
                      :error-messages="rolesErrors"
                      @input="$v.formData.roles.$touch()"
                      @blur="$v.formData.roles.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </form>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        name: { required },
        email: { required, email },
        roles: { required },
        user_type_id: { required },
        warehouses: {
          required: requiredIf(() => {
            return this.organizationId != "7";
          }),
        },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      parent_id: null,
      name: null,
      email: null,
      description: null,
      photo: null,
      roles: [],
      warehouses: [],
      so_reference_code: null,
      user_type_id: null,
      on_leave: 0,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: { ...this.$store.getters.getItemForAction }.id };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/users/edit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config).then((response) => {
        this.serverData = response.data;
        this.formData = response.data.user;
        this.formData.photo = null;
        this.formData.on_leave = response.data.user.on_leave ? 1 : 0;
        this.pageLoader(false);
      });
    },
    /**
     * Create FormData obj and add data into it
     */
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] != "image" && item[0] != "photo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] == "image" || item[0] == "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/authorizations/users/update`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `User data has been updated.`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    /**
     * reset data to intial value
     */
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        id: null,
        parent_id: null,
        name: null,
        email: null,
        description: null,
        photo: null,
        roles: [],
        so_reference_code: null,
        warehouses: [],
        user_type_id: null,
        on_leave: 0,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.resetEditForm();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    booleanYesNoValues: function () {
      return [
        { text: "No", index: 0 },
        { text: "Yes", index: 1 },
      ];
    },
    organizationId() {
      return this.$store.getters.currentUser.data.organization_id;
    },
    isDataLoaded: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    rolesErrors() {
      return this.formData.roles.length > 0 ? null : "Roles are required.";
    },
    warehousesError() {
      return this.handleFormValidation("warehouses", this, "formData");
    },
    isSalesRefVisible() {
      return this.formData.roles.indexOf(9) !== -1;
    },
    user_type_idError() {
      return this.handleFormValidation("user_type_id", this);
    },
  },
};
</script>
